<template>
  <div class="">
    <center-title title="发票明细"></center-title>
    <el-table :data="billList" style="width: 100%">
      <el-table-column align="center" prop="settle_sn" label="结算批次号" width="220">
      </el-table-column>
      <el-table-column align="center" prop="order_sn" label="订单编号" width="180">
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="订单金额" width="160">
      </el-table-column>
      <el-table-column align="center" prop="entry_account_amount" label="发起对账金额" width="160">
      </el-table-column>
      <el-table-column align="center" prop="remaining_amount" label="剩余额度" width="160">
      </el-table-column>
      <el-table-column align="center" prop="status" label="商品信息" width="360">
        <template slot-scope="scope">
          <div class="row goods_box" v-for="(item, index) in scope.row.order_goods" :key="index">
            <el-image class="goods_img" :src="item.image" fit="cover"></el-image>
            <div>
              <div class="line2 sm">{{ item.goods_name }}</div>
              <div class="row-between mt10">
                <make-priceformat :price="item.goods_price" class="mr10" :subscriptSize="14" :firstSize="14"
                  :secondSize="14" color="#666"></make-priceformat>
                <span class="lighter xxs">X{{ item.goods_num }}</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" label="收件人信息" width="300">
        <template slot-scope="scope">
          <ul>
            <li>收货人：{{scope.row.consignee}}</li>
            <li>手机号码：{{scope.row.mobile}}</li>
            <li>收货地址：{{scope.row.address}}</li>
            <li>配送方式：{{scope.row.delivery_type_text}}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="buyer" label="下单人账号" width="160">
      </el-table-column>
      <el-table-column align="center" prop="order_complete_time" label="订单完成时间" width="180">
      </el-table-column>
    </el-table>
    <div class="invoice-footer row-end" v-if="false">
      <make-pagination :orderObject="orderObject" @resetList="resetList"></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import MakePagination from "@/components/MakePagination";
import moment from 'moment';
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {

      value: "",
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      page_no: 1,
      page_size: 10,
      orderObject: {},
      billList: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    getStatus(val) {
      let str = ''
      switch (parseInt(val)) {
        case 0:
          str = '待确认'
          break;
        case 1:
          str = '已确认'
          break;
        case 2:
          str = '已驳回'
          break;
        case 3:
          str = '已开票'
          break;
        case 4:
          str = '已收票'
          break;
        case 5:
          str = '已付款'
          break;
        case 6:
          str = '已收款'
          break;
        case 10:
          str = '已取消'
          break;
      }
      return str
    },
    resetList(e) {
      this.page_no = e;
      this.getBillLists();
    },
    getRecentMonth(n) {
      let month = moment(new Date()).subtract(n, 'months').format('YYYY-MM-DD');
      return month;
    },
    /**
     * @description 获取发票订单列表
     * @return void
     */
    getBillLists() {
      let { page_no, page_size } = this;
      this.$api
        .getBilSettlementRecordApi({
          page_no,
          page_size,
          settle_id: this.$route.query.id
        })
        .then((res) => {
          console.log(res)
          this.billList = res.data.list;
          this.orderObject = res.data;
        });
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.billList : [];
      // this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.billList.length;
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.billList.length;
    },
    onSubmit() { },
    toApply() {
      let arr = this.checkedCities.filter(el => !el.is_bill)
      if (arr.length == 0) {
        return
      }
    }
  },
  created() {
    this.getBillLists();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.goods_box {
  padding: 5px 0px;
  margin: 0 20px;
  border-bottom: $-solid-border;

  &:last-child {
    border-bottom: none;
  }

  .goods_img {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    margin-right: 10px;
  }
}
ul{
  li{
    line-height: 2;
    text-align: left;
    padding: 0 20px;
  }
}

.operation {
  padding: 10px 0;

  .el-dropdown {
    font-size: 16px;
  }

  .item {
    ::v-deep .el-select {
      .el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 16px;
        text-align: center;
      }

      input::-webkit-input-placeholder {
        color: $-color-normal;
      }

      input::-moz-placeholder {
        color: $-color-normal;
      }

      input:-moz-placeholder {
        color: $-color-normal;
      }

      input:-ms-input-placeholder {
        color: $-color-normal;
      }

      .el-input__icon {
        color: $-color-normal;
      }

      .el-input__suffix {
        right: 15px;
      }
    }
  }
}

.order-list {
  .order-item {
    margin-bottom: 15px;

    ::v-deep .el-checkbox__label {
      display: none;
    }

    .item-title {
      height: 48px;
      line-height: 48px;
    }

    .order-box {
      border: 1px solid $-color-center;
      border-top: none;
      align-items: flex-start;
    }

    .pro-list {
      .pro-item {
        padding: 20px 0;
        border-bottom: 1px solid $-color-center;
        border-right: 1px solid $-color-center;

        &:last-child {
          border-bottom: none;
        }

        .item-info {
          .item-img {
            width: 64px;
            height: 64px;
            flex-shrink: 0;
          }
        }
      }
    }

    .pro-right {
      padding-top: 44px;

      .operation-btn {
        width: 72px;
        height: 28px;
        border: 1px solid $-color-primary;
        border-radius: 4px;
        display: inline-block;
        color: $-color-primary;
        line-height: 26px;
      }
    }
  }
}

.invoice-footer {
  button {
    width: 80px;
    height: 32px;
    border: 1px solid $-color-warn;
    border-radius: 4px;
  }
}



::v-deep .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 10px 0px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>